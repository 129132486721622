import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {tdsSharedRoutes} from "@core/components/tds/tds-shared.routes";


export const routes: Routes = [
  {path: 'devis', loadChildren: () => import('./devis/devis.module').then(m => m.DevisModule)},
  {
    path: 'souscription',
    loadChildren: () => import('./souscription/souscription.module').then(m => m.SouscriptionModule)
  },
  {path: 'paiement', loadChildren: () => import('./paiement/paiement.module').then(m => m.PaiementModule)},
  ...tdsSharedRoutes,
  {path: '', redirectTo: 'devis', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
