import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BASE_API_URL } from "../base-api.url";
import { Observable } from "rxjs";
import { BrokerResource } from "@core/backend/models/broker/brokerResource";

@Injectable({
  providedIn: "root"
})
export class BrokerService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string) {

  }

  public getBrokerByCodeCobranding(codeCobranding: string): Observable<BrokerResource> {
    return this.httpClient.get<BrokerResource>(`${this.baseUrl}broker/${codeCobranding}`, {
      responseType: "json",
      headers: { "Accept": "application/json" }
    });
  }

}
