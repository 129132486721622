<div class="tds-helpmobile">
  <div class="tds-helpmobile-header">
    <h3>Besoin d'aide ?</h3>
    <p i18n="@@App.Aide.Description">
      Nos conseillers sont disponibles du lundi au vendredi, de 9h à 12h et de 13h à 18h (CET)
    </p>
  </div>
  <div class="tds-helpmobile-call">
    <a href="tel:+33144204877" mat-stroked-button (click)="openLink()">
      <mat-icon>call</mat-icon> +33 1 44 20 48 77
    </a>
    <span i18n="@@App.Aide.Appel.Info">Appel non surtaxé</span>
  </div>
  <div class="tds-helpmobile-separator" >
    <span i18n="@@App.Aide.Ou">Ou</span>
  </div>
  <div class="tds-helpmobile-callback">
    <a mat-stroked-button i18n="@@App.Aide.Rappel" routerLink="/demande-rappel" (click)="openLink()">
      Être Rappelé·e
    </a>
  </div>
</div>
