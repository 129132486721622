import {ErrorHandler, Injectable, NgZone} from "@angular/core";
import {BehaviorSubject, map, merge, retry, scan, Subject} from "rxjs";
import {Action, ActionReducer} from "@ngrx/store";
import {DateTime} from "luxon";
import {CoreState} from "@core/reducers/core.state";

@Injectable()
export class LogErrorHandler implements ErrorHandler {

  public static RuntimeErrors$: Subject<unknown> = new Subject<unknown>();

  constructor(private zone: NgZone) {
  }

  handleError(error: unknown) {
    this.zone.run(()=>{
      LogErrorHandler.RuntimeErrors$.next(error);
      console.error(error);
    })
  }
}


export class EventLoggerService{

  public static Actions$ : Subject<{action: Action, nextState: CoreState}> = new Subject<{action: Action; nextState: CoreState}>();

  public static journal$ = new BehaviorSubject<EventLoggerEntry[]>([]);

  public static Init(){
    merge(
      LogErrorHandler.RuntimeErrors$.pipe(map((error)=>{
        return {
          date: DateTime.local(),
          kind:'error' as const,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error: (error instanceof Error) ? error.toString() : JSON.stringify(error as any)
        } as EventLoggerEntry;
      })),
      EventLoggerService.Actions$.pipe(map(({action, nextState})=>{
        return {
          kind:'action' as const,
          actionKind:action.type,
          date: DateTime.local(),
          action:action,
          state:nextState
        } as EventLoggerEntry;
      }))
    ).pipe(
      retry(),
      scan((acc : EventLoggerEntry[] , cell : EventLoggerEntry)=>{
        return EventLoggerService.clearJournal([...acc, cell]);
      }, [] as EventLoggerEntry[]))
      .subscribe(EventLoggerService.journal$);
  }

  private static clearJournal(journal: EventLoggerEntry[]) {
    const journalOrdonneParOrdreInverse = [...journal].sort((a,b)=>b.date.diff(a.date).toMillis());
    return journalOrdonneParOrdreInverse.slice(0, 50);
  }
}

type EventLoggerEntry = {
  date: DateTime;
  kind:'action',
  actionKind:string,
  action:Action,
  state?:CoreState
} | {
  date: DateTime;
  kind:'error',
  error:string,
};


export function logActionsMetareducer<TState extends CoreState>(reducer: ActionReducer<TState>): ActionReducer<TState> {
  return (stateBefore, action) => {
    const stateAfter = reducer(stateBefore, action);
    EventLoggerService.Actions$.next({action:action, nextState:stateAfter as unknown as CoreState});
    return stateAfter;
  };
}
