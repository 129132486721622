import {InjectionToken} from "@angular/core";

export interface MentionLegalesOptions {
  codeProduitPourTelechargementFichier:string;
}


export const CORE_MENTIONS_LEGALES_OPTIONS = new InjectionToken<MentionLegalesOptions>('CORE_MENTIONS_LEGALES_OPTIONS');

export function provideMentionsLegales(options: MentionLegalesOptions) {
  return {provide: CORE_MENTIONS_LEGALES_OPTIONS, useValue: options};
}
