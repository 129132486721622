import {InteractionModel} from "@core/forms/model.directive";

export interface DemandeRappelFeatureState{
  donnees: DemandeRappelState,
  interactionStatus: InteractionModel<DemandeRappelState>
}

export interface DemandeRappelState {
  prenom: string | null,
  nom: string | null,
  indicatifTelephonique: string | null,
  numeroTelephone: string | null,
  email: string | null,
  commentaire: string,
  captchaToken: string | null
}

export const initialState : DemandeRappelFeatureState={
  donnees: {
    prenom: null,
    nom: null,
    indicatifTelephonique: null,
    numeroTelephone: null,
    email: null,
    commentaire: "",
    captchaToken: null
  },

  interactionStatus: {
    prenom: 'pristine',
    nom: 'pristine',
    indicatifTelephonique: 'pristine',
    numeroTelephone: 'pristine',
    email: 'pristine',
    commentaire: 'pristine',
    captchaToken: 'pristine'
  },
}
