<ng-container>

  <div class="tds-card">
    <div class="tds-card-header">
      <div class="tds-card-header-title">
        <!-- eslint-disable  @angular-eslint/template/click-events-have-key-events -->
        <!-- eslint-disable   @angular-eslint/template/interactive-supports-focus -->
        <a class="lienRetour" (click)="goBack()"
           style="display: inline; margin-left: 10px;margin-right: 10px;margin-bottom: 20px;vertical-align: middle;">
          <mat-icon>
            arrow_back
          </mat-icon>
        </a>
        <!-- eslint-enable   @angular-eslint/template/interactive-supports-focus -->
        <!-- eslint-enable  @angular-eslint/template/click-events-have-key-events -->
        <h2 i18n="@@ConditionsGenerales.Headline">CONDITIONS GENERALES D'UTILISATION</h2>
      </div>
    </div>

    <div class="tds-card-content">

      <div class="tds-card-content-ct">

        <div class="tds-subcards">

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Intro">
            <p><strong>MSH International</strong>, société par actions simplifiée au capital de 2 500 000 €
              dont le siège social est situé 39 rue Mstislav Rostropovitch - 75017 Paris, immatriculée au
              Registre du Commerce et des Sociétés de Paris sous le n°352 807 549, enregistrée à l'ORIAS
              sous le n°07 002 751 - <a href="https://www.orias.fr"
                                        target="_blank">https://www.orias.fr</a>, soumise au contrôle de l'ACPR - <a
                href="https://acpr.banque-france.fr" target="_blank">https://acpr.banque-france.fr</a>
              (ci-après « MSH International » ou « MSH ») met à disposition du public (ci-après
              l'« Utilisateur ») le site Internet : <a
                href="https://individualquote-neoyouth.msh-intl.com"
                target="_blank">https://individualquote-neoyouth.msh-intl.com</a> (ci-après « Site
              Internet ») permettant de générer un devis et souscrire un contrat d'assurance par son
              intermédiaire. </p>
            <p>L'accès et l'utilisation du Site Internet est soumis à l'acceptation des présentes Conditions
              Générales d'Utilisation (ci-après les « CGU »).</p>
            <p>L'utilisation du Site Internet entraîne l'acceptation pleine et entière des présentes. </p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Definitions">
            <p><strong>ARTICLE 1 - DEFINITIONS</strong></p>
            <p>Les termes commençant par une majuscule ont la signification qui leur est respectivement
              conférée dans le présent article ou à défaut, la signification qui leur est conférée dans
              les CGU.</p>

            <p><strong>Services :</strong> différents services et offres commerciales proposés par MSH
              International via le Site Internet, tels que décrits dans l'article 4 - INFORMATIONS SUR LES
              SERVICES PROPOSES.</p>

            <p><strong>Site Internet :</strong> site Internet à partir duquel l'Utilisateur accède aux
              informations et aux Services fournis par MSH International.</p>

            <p><strong>Utilisateur :</strong> toute personne utilisant l'un des services fournis par le Site
              Internet.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Objet">
            <p><strong>ARTICLE 2 - OBJET</strong></p>
            <p>Les présentes CGU ont pour objet de définir les termes et conditions selon lesquels
              l'Utilisateur utilise le Site Internet et les différents Services proposés.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Acces">
            <p><strong>ARTICLE 3 - ACCES AU SITE INTERNET ET AUX SERVICES</strong></p>
            <p>Les Services proposés par MSH International sont accessibles gratuitement (hors coût de
              connexion) à tout Utilisateur disposant d'un accès Internet. Tous les frais liés à l'accès
              aux Services, qu'ils soient matériels, logiciels ou accès Internet, sont à la charge
              exclusive de l'Utilisateur qui est seul responsable du bon fonctionnement de son équipement
              informatique et de son accès internet.</p>
            <p>MSH International se réserve le droit de refuser l'accès à tout ou partie des Services,
              unilatéralement et sans préavis, à tout Utilisateur qui ne respecterait pas les présentes
              CGU.</p>
            <p>Lorsqu'il est invité à fournir des informations, l'Utilisateur accepte que ces informations
              soient exactes et à jour.</p>
            <p>L'Utilisateur s'engage à ne pas copier, corrompre ou tenter de copier, modifier ou corrompre
              de quelque manière que ce soit le contenu ou la programmation informatique des pages du Site
              Internet qui donnent accès aux Services et à leur utilisation.</p>
            <p>L'Utilisateur s'engage à respecter toutes les réglementations applicables du pays dans lequel
              il utilise les Services et notamment à ne présenter aucun document, donnée ou information à
              caractère diffamatoire, injurieux, contrefaisant, illégal, inexact ou falsifié ou qui
              constituerait une atteinte à l'ordre public ou un outrage aux bonnes mœurs ou qui nuisent au
              fonctionnement technique des Sites Internet ou des Services.</p>
            <p>MSH International se réserve le droit de supprimer toute demande d'accès aux Services
              proposés sur le Site Internet qui serait manifestement contraire à l'ordre public ou aux
              bonnes mœurs ou de poursuivre tout Utilisateur ayant commis un délit ou acte qui viole ces
              dispositions ou les lois et règlements en vigueur.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Informations">
            <p><strong>ARTICLE 4 - INFORMATIONS SUR LES SERVICES PROPOSES</strong></p>
            <p><strong>4-a / Description</strong></p>
            <p>Le Site Internet permet à l'Utilisateur de consulter les produits d'assurance proposés par
              MSH International et, sous certaines conditions, de souscrire ces produits en ligne. Pour
              souscrire, l'Utilisateur doit être âgé d'au moins 18 ans.</p>
            <p><strong>4-b / Informations sur les Services et produits d'assurance</strong></p>
            <p>Les informations présentes sur le Site Internet sont fournies à titre d'information générale
              et ne doivent pas être considérées comme des conseils. Malgré les mises à jour du contenu du
              Site Internet, MSH International ne pourra être tenue responsable des modifications des
              dispositions administratives et juridiques survenant suite à leur
              publication. L'Utilisateur, quel que soit son lieu de résidence, est considéré comme ayant
              visité le Site Internet de sa propre initiative pour s'informer sur les Services et les
              produits proposés. Les simulations présentées sur le Site Internet ont pour objectif de
              fournir à l'Utilisateur des informations à titre indicatif uniquement. MSH International se
              réserve le droit, à tout moment et sans préavis, de modifier et mettre à jour ces
              informations ainsi que le contenu des offres commerciales. L'Utilisateur sera informé par
              tout moyen. </p>
            <p><strong>4-c / Modalités d'obtention d'un devis ou de souscription du contrat
              d'assurance</strong></p>
            <p><strong>Obtenir un devis en ligne</strong><br/>
              Lorsque l'Utilisateur demande un devis ou effectue une souscription sur le Site Internet, il
              lui est demandé de remplir un questionnaire contenant les informations personnelles
              nécessaires au traitement de sa demande. Ces informations personnelles sont nécessaires pour
              que la demande soit prise en compte. Ces informations sont indispensables à l'appréciation
              de la situation de l'Utilisateur et à la fixation du montant de la prime. Si l'Utilisateur
              l'accepte, le contrat d'assurance est conclu sur la base des informations enregistrées dans
              le questionnaire. 
            </p>
            <p><strong>Souscrire un contrat d'assurance en ligne</strong><br/>
              Pour souscrire le contrat d'assurance, l'Utilisateur doit suivre les instructions données à
              chaque étape du processus de souscription en ligne. Chaque étape nécessite une confirmation
              de la part de l'Utilisateur avant de passer à la suivante. Lors du processus de souscription
              en ligne, l'Utilisateur peut à tout moment apporter des corrections en revenant à l'étape
              précédente grâce au lien prévu à cet effet. A la fin de la souscription, l'Utilisateur
              recevra un e-mail de confirmation à l'adresse e-mail fournie confirmant la réception de la
              demande d'adhésion. Cet email contient un lien permettant à l'Utilisateur de retrouver
              l'accès à son adhésion stockée dans une base de données sécurisée et accessible depuis
              l'Espace Assuré.
            </p>
            <p><strong>4-d / Droit de renonciation</strong></p>
            <p>En application de l'article L112-2-1 du Code des assurances, l'Utilisateur peut renoncer à
              son adhésion sans avoir à justifier de motif, en adressant une lettre à MSH International à
              l'adresse indiquée dans les conditions générales du contrat qui lui ont été fournies lors de
              la souscription, dans les 14 jours à compter de la réception du certificat d'adhésion.
            </p>
            <p>
              Pour ce faire, l'Utilisateur peut utiliser le modèle de lettre suivant :
              « Je soussigné(e), ............................... .......... [nom, prénom], demeurant
              [adresse complète] souhaite renoncer à mon adhésion au contrat d'assurance n°
              ................... ........... [numéro de référence indiqué sur le certificat d'adhésion]
              souscrit le .............. [date].
              Fait à ......... le .......... ... et [Signature] ......... »
            </p>
            <p>
              Si l'Utilisateur exerce cette faculté, il sera remboursé de la prime correspondante.<br/>
              Le droit de renonciation ne peut pas être exercé si l'Utilisateur a mis en œuvre des
              garanties du présent contrat d'assurance dans le cadre d'un sinistre déclaré pendant le
              délai indiqué ci-dessus ; par conséquent aucun remboursement de prime ne sera effectué. </p>
            <p><strong>4-f/ Preuve d'adhésion et conservation des documents</strong></p>
            <p>Les documents contractuels sont conservés électroniquement à valeur probante par la société
              MSH International auprès d'un tiers et dans le respect des normes en vigueur, et pourront
              être produits dans le cadre de toute procédure contentieuse ou de médiation. L'Utilisateur
              doit conserver ses documents contractuels sur papier ou sous forme électronique. En cas de
              perte, l'Utilisateur pourra accéder à ces documents en en faisant la demande auprès de la
              Société MSH International à l'adresse indiquée dans les Conditions Générales du contrat
              fournies lors de l'adhésion au contrat d'assurance. Les frais de récupération des documents
              seront facturés à l'Utilisateur au tarif en vigueur au jour de la demande.</p>

          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Accessibilite">
            <p><strong>ARTICLE 5 – ACCESSIBILITE</strong></p>
            <p>Le Site Internet est disponible 24h/24 et 7j/7. Toutefois, MSH International pourra fermer ou
              rendre inaccessible tout ou partie du Site Internet notamment pour en assurer la maintenance
              ou à la demande de toute autorité judiciaire et en cas de force majeure. En aucun cas MSH
              International ne sera responsable de l'indisponibilité du Site Internet et des Services et
              de toute interruption totale ou partielle de la consultation de leurs pages. L'Utilisateur
              doit vérifier qu'il dispose des outils et moyens de communication électroniques, des droits
              d'accès accordés par MSH International et des compétences pour accéder au Site et aux
              Services.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Propriete">
            <p><strong>ARTICLE 6 – PROPRIETE INTELECTUELLE </strong></p>
            <p><strong>6-a / Droits d'auteur - copyright</strong><br/>
              Tous les éléments de toute nature (images fixes, images animées, bases de données, marques,
              illustrations, logos, dessins, modèles, mise en page, documents téléchargeables) contenus
              dans sur le Site Internet sont protégés en tant qu'œuvre de l'esprit par la législation
              française et internationale sur le droit d'auteur et la propriété intellectuelle. Ces
              éléments restent la propriété exclusive du groupe DIOT - SIACI, sauf mentions particulières.
              A ce titre, sauf autorisation préalable et écrite, toute reproduction, représentation,
              adaptation, modification partielle ou intégrale de tout élément composant le Site Internet,
              par quelque moyen que ce soit, est interdite sous peine de poursuite judiciaire.<br/><br/>
              Les marques et logos reproduits sur le Site Internet sont déposés. Toute reproduction,
              réédition ou redistribution totale ou partielle des marques ou logos, par quelque moyen que
              ce soit, sans autorisation préalable et écrite est interdite.</p>
            <p><strong>6-b / Utilisation du Site Internet</strong><br/>
              L'utilisateur accepte de ne pas adapter ou de ne pas changer tout ou partie du Site
              Internet,
              ou de ne pas l'utiliser dans un autre but que son usage personnel et non commercial.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Responsabilite">
            <p><strong>ARTICLE 7 – RESPONSABILITE</strong></p>
            <p>Les informations contenues sur ce Site Internet n'ont aucune valeur contractuelle et peuvent
              être modifiées à tout moment par MSH International. MSH International ne garantit pas
              l'exactitude des informations contenues sur ce Site Internet ; en aucun cas la société MSH
              International ne pourra être tenue responsable du fait de l'utilisation des informations
              mises à disposition sur ce Site Internet ou de l'absence d'informations spécifiques.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Modification">
            <p><strong>ARTICLE 8 - MODIFICATION DES CGU </strong></p>
            <p>MSH International se réserve le droit, d'apporter des modifications aux présentes CGU, qui
              seront soumises à acceptation de l'Utilisateur. </p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.ForceMajeure">
            <p><strong>ARTICLE 9 - FORCE MAJEURE</strong></p>
            <p>MSH International ne pourra être tenue responsable de tout préjudice, perte ou dommage
              résultant d'un cas de force majeure tel que défini par la loi et la jurisprudence française.
            </p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Reclamations">
            <p><strong>ARTICLE 10 - RECLAMATIONS </strong></p>
            <p>Lorsqu'un Utilisateur est mécontent du traitement de sa demande, sa première démarche doit
              être d'en informer son interlocuteur habituel pour que la nature de son insatisfaction soit
              comprise et que des solutions soient recherchées.<br/>
              En cas de désaccord, l'adresse à retenir pour adresser une réclamation est la suivante :</p>
            <p style="text-align: center;">
              MSH International<br/>
              Service Réclamation<br/>
              23 allées de l'Europe - 92 587 Clichy Cedex - France</p>
            <p>Pour les modalités de traitement de sa réclamation l'Utilisateur devra se référer aux
              conditions générales du contrat d'assurance.</p>
            <p>Si l'Utilisateur n'est toujours pas satisfait du traitement de sa réclamation, il peut alors
              saisir le médiateur indépendant dont les coordonnées sont les suivantes</p>
            <p>La Médiation de l'Assurance<br/>
              <a href="http://www.mediation-assurance.org"
                 target="_blank">http://www.mediation-assurance.org</a><br/>
              ou<br/>
              La Médiation de l'Assurance <br/>
              TSA 50110<br/>
              75441 PARIS Cedex 09
            </p>
            <p>En outre, l'Utilisateur peut déposer une plainte auprès de la plateforme en ligne mise à
              disposition par la Commission européenne <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR"
                target="_blank">(Règlement en ligne des litiges | Commission
                européenne (europa.eu)</a> ou s'adresser à l'autorité de surveillance compétente, à
              savoir
              l'Autorité de contrôle prudentiel et de résolution (ACPR) – 4 Place de Budapest – 75436
              Paris.
              L'Utilisateur a toujours la possibilité d'intenter toute action en justice.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Droit">
            <p><strong>ARTICLE 11 - DROIT APPLICABLE - JURIDICTION COMPETENTE</strong></p>
            <p>Les présentes CGU sont régies par le droit français. <br/>
              Tout litige relatif aux CGU sera soumis devant les juridictions françaises compétentes.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@ConditionsGenerales.Autorite">
            <p><strong>ARTICLE 12 - AUTORITE DE CONTROLE  </strong></p>
            <p>L'organisme chargé du contrôle de MSH International est l'Autorité de contrôle prudentiel et
              de résolution, sise 4, Place de Budapest, CS 92 459, 75436 Paris Cedex 09 - <a
                href="www.acpr.banque-france.fr" target="_blank">www.acpr.banque-france.fr</a></p>
          </div>

        </div>
      </div>
    </div>
  </div>

</ng-container>
