import {DemandeRappelState, initialState} from "@core/components/tds/demande-rappel/store/demande-rappel.state";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {withDirtyField, withTouchedField} from "@core/forms/model.directive";
import {withFieldValue} from "@core/helpers/object.helpers";
import {getAllErrorMessages, getModelStatus, ModelStatusDefinition} from "@core/validation/model.validation";
import {isEmailValide} from "@core/helpers/email-manipulation.helper";
import {stringIsNullOrEmpty, stringIsNullOrEmptyOrEqualLessThan} from "@core/helpers/strings.helpers";
import {DemandeRappelResource} from "@core/backend/models/shared/demandeRappelResource";
import {etapeDemandeRappelActions} from "@core/components/tds/demande-rappel/store/demande-rappel.actions";

export const demandeRappelFeature= createFeature({
  name:'demandeRappel',
  reducer: createReducer(initialState,
    on(etapeDemandeRappelActions.utilisateurAToucheUnChamps, (state, value) => {
      return {
        ...state,
        interactionStatus: withTouchedField<DemandeRappelState>(state.interactionStatus!, value.fieldname)
      }
    }),
    on(etapeDemandeRappelActions.utilisateurAModifieUnChamps, (state, value) => {
      return {
        ...state,
        donnees: withFieldValue<DemandeRappelState>(state.donnees, value.fieldname, value.fieldvalue),
        interactionStatus: withDirtyField<DemandeRappelState>(state.interactionStatus!, value.fieldname)
      }
    }),
    on(etapeDemandeRappelActions.utilisateurACliquerSurDemandeDeRappel, (state)=>{
      return {
        ...state,
        interactionStatus: {
          captchaToken: 'pristine' as const,
          commentaire: 'pristine' as const,
          email: 'pristine' as const,
          indicatifTelephonique: 'pristine' as const,
          nom: 'pristine' as const,
          numeroTelephone: 'pristine' as const,
          prenom: 'pristine' as const,
        },
        donnees: {
          captchaToken: null,
          commentaire: "",
          email: null,
          indicatifTelephonique: null,
          nom: null,
          numeroTelephone: null,
          prenom: null
        }
      }
    })
  ),
  extraSelectors:({selectDonnees})=>{
    const modelStatus = createSelector(
      selectDonnees,
      (s) => {
        const modelStatusDefinition: ModelStatusDefinition<DemandeRappelState> = {
          email: {
            _validationRules: [
              (_, value) => value != null && value != '' ? null : $localize`:@@DemandeRappel.Validation.EmailObligatoire:L'e-mail est obligatoire`,
              (_, value) => isEmailValide(value, 'EmptyValueIsValid') ? null : $localize`:@@DemandeRappel.Validation.EmailInvalide:L'e-mail est invalide`,
              (_, value) => stringIsNullOrEmpty(value) || value!.length <= 100 ? null : $localize`:@@DemandeRappel.Validation.EmailTropLong:L'e-mail ne doit pas faire plus de 100 caractères`,
            ],
          },
          indicatifTelephonique: {
            _validationRules: [
              (_, value) => !stringIsNullOrEmpty(value) ? null : $localize`:@@DemandeRappel.Validation.IndicatifObligatoire:L'indicatif du téléphone est obligatoire`,
              (_, value) => stringIsNullOrEmptyOrEqualLessThan(value, 6) ? null : $localize`:@@DemandeRappel.Validation.IndicatifTropLong:L'indicatif ne doit pas faire plus de 6 caractères`,
            ]
          },
          numeroTelephone: {
            _validationRules: [
              (_, value) => !stringIsNullOrEmpty(value) ? null : $localize`:@@DemandeRappel.Validation.NumeroTelephoneObligatoire:Le numéro de téléphone est obligatoire`,
              (_, value) => stringIsNullOrEmptyOrEqualLessThan(value, 14) ? null : $localize`:@@DemandeRappel.Validation.NumeroTelephoneTropLong:Le numéro de téléphone ne doit pas faire plus de 14 caractères`,
            ]
          },
          nom: {
            _validationRules: [
              (_, value) => !stringIsNullOrEmpty(value) ? null : $localize`:@@DemandeRappel.Validation.NomObligatoire:Le nom est obligatoire`,
              (_, value) => stringIsNullOrEmptyOrEqualLessThan(value, 40) ? null : $localize`:@@DemandeRappel.Validation.NomTropLong:Le nom ne doit pas faire plus de 40 caractères`,
            ]
          },
          prenom: {
            _validationRules: [
              (_, value) => !stringIsNullOrEmpty(value) ? null : $localize`:@@DemandeRappel.Validation.PrenomObligatoire:Le prénom est obligatoire`,
              (_, value) => stringIsNullOrEmptyOrEqualLessThan(value, 40) ? null : $localize`:@@DemandeRappel.Validation.PrenomTropLong:Le prénom ne doit pas faire plus de 40 caractères`,
            ]
          },
          captchaToken: {
            _validationRules: [
              (_, value) => !stringIsNullOrEmpty(value) ? null : $localize`:@@DemandeRappel.Validation.CaptchaObligatoire:Le captcha doit être renseigné`,
            ]
          },
        };

        if (s == null)
          return null;
        return getModelStatus(s, modelStatusDefinition);
      }
    );
    return {
      selectModelStatus: modelStatus,
      selectIsEtapeValide: createSelector(
        modelStatus,
        (modelStatus) => {
          if (modelStatus == null) {
            return true;
          }
          return getAllErrorMessages(modelStatus).length === 0;
        }
      ),
      selectDemandeRappelResource: createSelector(
        selectDonnees,
        (demandeRappel) => {
          if (demandeRappel == null || demandeRappel.prenom == null || demandeRappel.nom == null || demandeRappel.indicatifTelephonique==null || demandeRappel.numeroTelephone == null || demandeRappel.email == null || demandeRappel.commentaire == null || demandeRappel.captchaToken==null){
            return null;
          }

          //Ce sélecteur renvoie un DemandeRappelResource, hormis le champs nomCommercial
          //Car pour le déterminer, il nous faudrait accéder au sélecteur selectors.selectNomCommercial du DemandeRappelOptions injecté
          //Hors, il ne semble pas possible d'utiliser l'injection dans les features
          //Donc on laisse le soin à l'effect qui va utiliser selectDemandeRappelResource de récupérer lui-même le nom commercial
          const ressource : Omit<DemandeRappelResource, 'nomCommercial'> = {
            prenom: demandeRappel.prenom,
            nom: demandeRappel.nom,
            indicatifTelephonique: demandeRappel.indicatifTelephonique,
            numeroTelephone: demandeRappel.numeroTelephone,
            email: demandeRappel.email,
            commentaire: demandeRappel.commentaire,
            captchaToken: demandeRappel.captchaToken,
          };

          return ressource;
        }
      )
    };
  }

});
