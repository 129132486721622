import {isDevMode} from '@angular/core';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {CoreState, getCoreMetaReducers} from "@core/reducers/core.state";
import {logActionsMetareducer} from "@core/errors/logErrorHandler";

/**
 * Etat global de l'application WAY.
 * Ne contient que les parties de l'état partagé dans toute l'application WAY
 * Cet état sera agrémenté au runtime de tous les états des différentes [features](https://ngrx.io/guide/store/feature-creators) des modules lazy-loadés
 */
export interface WayState extends CoreState {

}


/**
 * Liste des reducers de l'application WAY
 */
export const reducers: ActionReducerMap<WayState> = {

};



/**
 * Liste des meta reducers : fonctions qui vont intercepter les actions et les états
 */
export const metaReducers: MetaReducer<WayState>[] = [
  //On reprend les metareducers de la librairie core
  ...getCoreMetaReducers<WayState>(),
  //On ajoute les metareducers spécifiques à l'application WAY
  ...(isDevMode() ? [
    logActionsMetareducer
  ] : [])
];
