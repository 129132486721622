import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {initialState} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.state";
import {parametrageDebogageActions} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.actions";
import {chargementReferentielActions} from "@core/referentiel/chargementReferentiel.actions";

export const parametrageDeboggageServeurFeature = createFeature({
  name: 'parametrageDeboggageServeur',
  reducer:createReducer(initialState,
    on(chargementReferentielActions.referentielCharged, (state, action) => {
      return {
        ...state,
        donnees: action.parametrageDebogageServeur,
      }
    }),
    on(parametrageDebogageActions.utilisateurAToggledEmailBrevo, (state) => {
      return {
        ...state,
        donnees: {
          ...state.donnees!,
          isFakingEmailBrevo: !state.donnees!.isFakingEmailBrevo,
        },
      }
    }),
    on(parametrageDebogageActions.utilisateurACliqueSurBoutonSauvegarder, (state) => {
      return {
        ...state,
        isUpdatingParametrageDebogageServeur: true
      }
    }),
    on(parametrageDebogageActions.aPIAReponduAvecParametrageDebogageServeur, (state, action) => {
      return {
        ...state,
        donnees: action.parametrage,
        isUpdatingParametrageDebogageServeur: false
      }
    }),
    on(parametrageDebogageActions.utilisateurACliqueSurBoutonAnnuler, (state, action) => {
      return {
        ...state,
        donnees: action.parametrageInitial,
      }
    }),
  ),
  extraSelectors: ({selectDonnees, selectIsUpdatingParametrageDebogageServeur})=>{
    return {
      selectResumeParametrageDebogage: createSelector(
        selectDonnees,
        selectIsUpdatingParametrageDebogageServeur,
        (donnees, isUpdatingParametrageDebogageServeur) => {

          const result: ResumeParametrageDebogageState = {
            //TODO : améliorer cela : le serveur pourrait juste ne pas nous renvoyer de parametrageDebogageServeur si pas en débogage
            parametrageVisible: donnees?.isFakingEmailBrevo != null,
            hasAnyWarning: donnees?.isFakingEmailBrevo === false,
            isUpdatingParametrage:isUpdatingParametrageDebogageServeur
          }

          return result;
        }
      )
    }
  }
})

interface ResumeParametrageDebogageState {
  parametrageVisible: boolean
  hasAnyWarning: boolean,
  isUpdatingParametrage: boolean,
}
