import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BASE_API_URL} from "../base-api.url";
import {Observable} from "rxjs";
import {ParametrageDebogageServeurResource} from "@core/backend/models/shared";

@Injectable({
  providedIn: "root"
})
export class ParametrageDebogageServeurService {

  constructor(private httpClient: HttpClient, @Inject(BASE_API_URL) private baseUrl: string) {

  }

  public getParametrage(): Observable<ParametrageDebogageServeurResource> {
      return this.httpClient.get<ParametrageDebogageServeurResource>(`${this.baseUrl}parametrageDebogageServeur`, {
        responseType: "json",
        headers: {"Accept": "application/json"}
      });
  }

  public updateParametrage(parametrage: ParametrageDebogageServeurResource): Observable<ParametrageDebogageServeurResource> {

      return this.httpClient.post<ParametrageDebogageServeurResource>(`${this.baseUrl}parametrageDebogageServeur`, parametrage, {
        responseType: "json",
        headers: {"Accept": "application/json"}
      });
  }
}
