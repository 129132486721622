import {EnvironmentProviders, InjectionToken, ValueProvider} from "@angular/core";
import {provideState, Selector} from "@ngrx/store";
import {ParametrageBase, SouscriptionResource} from "@core/backend/models/souscription";
import {IndicatifTelephoniqueResource} from "@core/backend/models/referentiel";
import {demandeRappelFeature} from "@core/components/tds/demande-rappel/store/demande-rappel.feature";
import {provideEffects} from "@ngrx/effects";
import * as injecterEvenementsDansDataLayer from "@core/components/tds/demande-rappel/store/effects/injecter-evenements-dans-data-layer.effect";
import * as demandeRappelEffect from "@core/components/tds/demande-rappel/store/effects/demande-rappel.effect";


export interface DemandeRappelOptions {
  selectors: {
    selectIndicatifsTelephoniques: Selector<object, IndicatifTelephoniqueResource[] | undefined>;
    selectNomCommercial: Selector<object, string | null>
    selectSouscriptionEnCours: Selector<object, SouscriptionResource<ParametrageBase> | null>
    selectDevisId: Selector<object, string | undefined>
  }
}


export const CORE_DEMANDE_RAPPEL_OPTIONS = new InjectionToken<DemandeRappelOptions>('CORE_DEMANDE_RAPPEL_OPTIONS');

export function provideDemandeRappel(options: DemandeRappelOptions) : Array<ValueProvider|EnvironmentProviders> {
  return [
    provideState(demandeRappelFeature),
    provideEffects([
      injecterEvenementsDansDataLayer,
      demandeRappelEffect
    ]),
    {provide: CORE_DEMANDE_RAPPEL_OPTIONS, useValue: options}
  ];
}
