import {ParametrageDebogageServeurResource} from "@core/backend/models/shared";

export interface ParametrageDeboggageServeurFeatureState{
  donnees: ParametrageDebogageServeurResource | null,
  isUpdatingParametrageDebogageServeur: boolean,
}

export const initialState: ParametrageDeboggageServeurFeatureState = {
  donnees: null,
  isUpdatingParametrageDebogageServeur: false,
};


