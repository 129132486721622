import {DemandeRappelComponent} from "@core/components/tds/demande-rappel/demande-rappel.component";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import {etapeDemandeRappelActions} from "@core/components/tds/demande-rappel/store/demande-rappel.actions";

export const resetDemandeRappel = (
  activatedRoute: ActivatedRouteSnapshot,
  stateSnapshot: RouterStateSnapshot,
  store = inject(Store),
): boolean => {
  store.dispatch(etapeDemandeRappelActions.utilisateurACliquerSurDemandeDeRappel());
  return true;
};

export const demandeRappelRoutes = [
  {path: 'demande-rappel', component: DemandeRappelComponent, canActivate: [resetDemandeRappel]},
]
