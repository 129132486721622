import {cguRoutes} from "@core/components/tds/cgu/cgu.routes";
import {mentionsLegalesRoutes} from "@core/components/tds/mentions-legales/mentions-legales.routes";
import {rpgdRoutes} from "@core/components/tds/rgpd/rpg.routes";
import {demandeRappelRoutes} from "@core/components/tds/demande-rappel/demande-rappel.routes";

export const tdsSharedRoutes= [
  ...cguRoutes,
  ...mentionsLegalesRoutes,
  ...rpgdRoutes,
  ...demandeRappelRoutes,
]
