import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, filter, map, merge, switchMap, tap } from 'rxjs';
import { BrokerService } from '@core/backend/services/broker/broker.service';
import { devisActions } from '@way/app/devis/store/devis.actions';
import { Store } from '@ngrx/store';
import { monTarifActions } from '@way/app/devis/mon-tarif/store/mon-tarif.actions';
import { globalActions } from '../global.actions';
import { recueilActions } from '@way/app/souscription/recueil/store/recueil.actions';
import { etapeConfirmationEmailEnvoyeActions } from '@way/app/souscription/etape-confirmation-email-envoye/store/etape-confirmation-email-envoye.actions';
import { etapePaiementSuccesActions } from '@way/app/paiement/etape-paiement-succes/store/etape-paiement-succes.actions';
import { etapePaiementActions } from '@way/app/paiement/etape-paiement/store/etape-paiement.actions';

export const chargerInfoBrokerEffect = createEffect(
  (
    actions$ = inject(Actions),
    brokerService = inject(BrokerService),
    store = inject(Store)
  ) => {

    return merge(

      actions$.pipe(
        ofType(devisActions.utilisateurANavigueVersDevis),
        filter((action)=>action.codeCobranding!=null),
        map((action)=>action.codeCobranding!)
      ),

      actions$.pipe(
        ofType(monTarifActions.apiARenvoyeDevis),
        filter((action)=>action.devis.codeCobranding!=null),
        map((action)=>action.devis.codeCobranding!)
      ),

      actions$.pipe(
        ofType(
          recueilActions.aPIAFourniSouscription, 
          etapeConfirmationEmailEnvoyeActions.aPIAFourniSouscription,
          etapePaiementSuccesActions.aPIAFourniSouscription,
          etapePaiementActions.aPIAFourniSouscription
          ),
        filter((action)=>action.souscription.codeCobranding!=null),
        map((action)=>action.souscription.codeCobranding!)
      ),

    ).pipe(
      switchMap((codeCobranding)=>{
        return brokerService.getBrokerByCodeCobranding(codeCobranding).pipe(
          tap((resultat)=>{
            store.dispatch(globalActions.apiARenvoyeNomBroker({codeCobranding:codeCobranding, nomBroker:resultat.nom }))
          }),
          catchError(()=>{
            store.dispatch(globalActions.apiNAPasRenvoyeBroker())
            return EMPTY;
          })
        );
      }),
    );
  },
  { functional: true, dispatch: false });
