import {Component, HostBinding, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {WayState} from '../store/way-state';
import {delay, distinctUntilChanged, filter, map, Observable, startWith, tap, throttleTime} from 'rxjs';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ContactBottomSheetComponent} from '@core/components/tds/contact-bottom-sheet/contact-bottom-sheet.component';
import {IS_MOBILE$} from "@core/helpers/mobile-management";
import {globalActions} from "@way/app/store/global.actions";

import {EventLoggerService, LogErrorHandler} from "@core/errors/logErrorHandler";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {globalFeature} from "@way/app/store/global.feature";
import {parametrageDeboggageServeurFeature} from "@way/app/parametrage-debogage-popup/store/parametrage-deboggage-serveur.feature";
import {etapeDemandeRappelActions} from "@core/components/tds/demande-rappel/store/demande-rappel.actions";
import {layoutFeature} from "@way/app/layout/store/layout.feature";
import {isHelpDisabled} from "@core/router/disable.help";

@Component({
  selector: 'way-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  protected version = runtimeEnv.APP_ENV!='prod'? runtimeEnv.DEPLOYMENT_VERSION : '';

  displaySpinner$ = this.store.select(layoutFeature.selectSpinnerModalAffiched).pipe(
    distinctUntilChanged(),
    //Pas besoin de faire clignoter le spinner si l'on passe oscille rapidement entre false et true, d'où le throttleTime
    throttleTime(200, undefined, { leading: true, trailing: true }),
  );
  @HostBinding('class.isMobile') isMobile = false;
  @HostBinding('class.exploitationVisible') exploitationVisible = false;

  public nomCommercial$ = this.store.select(globalFeature.selectNomCommercial);
  public nomBroker$ = this.store.select(globalFeature.selectNomBroker);
  public codeCobranding$ = this.store.select(globalFeature.selectCodeCobranding);
  public devisBrokerQueryParams = this.store.select(globalFeature.selectDevisRecueilAdditionalQueryParams);

  public readonly currentLanguage: string;

  protected resumeParametrageDebogage$ = this.store.select(parametrageDeboggageServeurFeature.selectResumeParametrageDebogage);
  protected errorCount$ = LogErrorHandler.RuntimeErrors$.pipe(map((_, index) => {
    return index + 1;
  }), startWith(0), delay(0));

  protected linksDebogage = [
    ...(runtimeEnv.MAILCATCHER_URL!=null ?[
    {
      "title": "Mailcatcher",
      "url": runtimeEnv.MAILCATCHER_URL
    }] : []),
    {
      "title": "CB Dalenys",
      "url": "https://developer.dalenys.com/ui/developer-doc/payment-methods/bank-card.html#sandbox-test-card-numbers"
    },
    {
      "title": "Amex Dalenys",
      "url": "https://developer.dalenys.com/ui/developer-doc/payment-methods/american-express.html#sandbox-test-card-numbers"
    }
  ]

  protected selectEtatSlider$ = this.store.select(layoutFeature.selectEtatSlider);

  protected afficherHelpDesk$ =  this.router.events.pipe(
    filter(f=>f instanceof NavigationEnd),
    map(()=> !isHelpDisabled(this.activatedRoute.snapshot)),
  );

  constructor(private store: Store<WayState>,
    @Inject(IS_MOBILE$) public isMobile$: Observable<boolean>,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    @Inject(LOCALE_ID) locale: string,
    private router: Router,
    private activatedRoute : ActivatedRoute,
    @Inject('Window') private window: Window) {
    this.currentLanguage = locale == 'en-gb' ? 'GB' : locale;
    this.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    })
  }

  ngOnInit(){
    this.store.select(parametrageDeboggageServeurFeature.selectResumeParametrageDebogage).pipe(
      tap((parametrage)=>{
        this.exploitationVisible = parametrage.parametrageVisible;
      })
    ).subscribe();
  }

  onPhoneClick($event: MouseEvent) {
    this.store.dispatch(etapeDemandeRappelActions.utilisateurACliquerSurBesoinAideMobile())
    this.bottomSheet.open(ContactBottomSheetComponent)
    $event.preventDefault();
  }

  changeLangue(langue: string, additionalQueryParams: {[key: string]: string}) {

    const url = new URL(this.window.location.origin + "/" + langue+'/devis/recueil');
    Object.keys(additionalQueryParams).forEach(key => {
      url.searchParams.append(key, additionalQueryParams[key]);
    });

    this.window.location.href = url.href;
  }

  onDebugParameterClick($event: MouseEvent) {
    this.store.dispatch(globalActions.utilisateurACliqueSurBoutonParametrageDebogage());
    $event.preventDefault();
  }

  onUtilisateurACliqueSurMentionsLegales() {
    this.store.dispatch(globalActions.utilisateurACliqueSurMentionsLegales());
  }

  onUtilisateurACliqueSurCGU() {
    this.store.dispatch(globalActions.utilisateurACliqueSurCGU());
  }

  onUtilisateurACliqueSurRGPD() {
    this.store.dispatch(globalActions.utilisateurACliqueSurRGPD());
  }


  async onUtilisateurACliqueSurErreurs() {
    const copyText = document.createElement('textarea');
    document.body.appendChild(copyText);
    const journal = EventLoggerService.journal$.getValue();
    console.log(journal);
    const json = JSON.stringify(journal, null, 3);
    console.log(json);
    copyText.textContent = json;
    copyText.select();
    copyText.setSelectionRange(0, copyText.textContent.length);
    await navigator.clipboard.writeText(copyText.value);
    document.body.removeChild(copyText);
    this.snackBar.open('Journal copié', 'OK');
  }
}
