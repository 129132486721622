import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {catchError, EMPTY, switchMap, tap} from "rxjs";
import {DemandeRappelService} from "@core/backend/services/shared/demande-rappel.service";
import {Store} from "@ngrx/store";
import {MatSnackBar} from "@angular/material/snack-bar";
import {demandeRappelFeature} from "@core/components/tds/demande-rappel/store/demande-rappel.feature";
import {etapeDemandeRappelActions} from "@core/components/tds/demande-rappel/store/demande-rappel.actions";
import {CORE_DEMANDE_RAPPEL_OPTIONS} from "@core/components/tds/demande-rappel/provide-demande.rappel";

export const demandeRappelEffect = createEffect(
  (
    actions$ = inject(Actions),
    demandeRappelService = inject(DemandeRappelService),
    store = inject(Store),
    snackBar = inject(MatSnackBar),
    options = inject(CORE_DEMANDE_RAPPEL_OPTIONS)
  ) => {
    return actions$.pipe(
      ofType(
        etapeDemandeRappelActions.utilisateurACliquerSurValider
      ),
      concatLatestFrom(() => [
        store.select(demandeRappelFeature.selectDemandeRappelResource),
        store.select(options.selectors.selectNomCommercial)
      ]),
      switchMap(([, demandeRappel, nomCommercial]) => {
        return demandeRappelService.postDemandeRappel({
            ...demandeRappel!,
            nomCommercial: nomCommercial
          }
        ).pipe(
          tap(() => {
            snackBar.open(
              $localize`:@@DemandeRappel.Confirmation.Message:Merci pour votre demande de rappel. Nous vous rappellerons sous peu.`,
              $localize`:@@DemandeRappel.Confirmation.Fermer:Fermer`,
              {
                horizontalPosition: "center",
                verticalPosition: "top"
              });
            history.back()
          }),
          catchError(() => {
            snackBar.open($localize`:@@DemandeRappel.Erreur.Message:Une erreur est survenue. Veuillez attendre quelques instants puis réessayer.`, $localize`:@@DemandeRappel.Confirmation.Fermer:Fermer`, {
              horizontalPosition: "center",
              verticalPosition: "top"
            });
            return EMPTY;
          })
        );
      })
    );
  }, {functional: true, dispatch: false}
);
