<!-- eslint-disable  @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable   @angular-eslint/template/interactive-supports-focus -->
<ng-container *ngrxLet="{ isEtapeValide: isEtapeValide$, modelStatus: modelStatus$, state: state$, interactionStatus: interactionStatus$, choixIndicatifs: choixIndicatifs$ } as model">
    <div class="tds-card">
        <div class="tds-card-header">
            <div class="tds-card-header-title">
                <a class="lienRetour" (click)="goBack()" style="display: inline; margin-left: 10px;margin-right: 10px;margin-bottom: 20px;vertical-align: middle;">
                    <mat-icon>
                        arrow_back
                    </mat-icon>
                </a>
                <h2 i18n="@@DemandeRappel.Headline">Vous avez besoin d'aide pour vous inscrire ?</h2>
            </div>
        </div>
        <div class="tds-card-content">
            <div class="tds-card-content-ct">
                <div class="tds-subcards">
                    <div class="tds-subcards-item noborder">
                        <p i18n="@@DemandeRappel.Description1">Laissez-nous vos coordonnées et nous vous recontacterons le plus vite possible.</p>
                        <ng-container coreModel
                            [model]="model.state" [modelStatus]="model.modelStatus"
                            [interactionStatus]="model.interactionStatus"
                            (userChanged)="onUserAModifieValeurFormulaire($event.fieldName, $event.value)"
                            (userTouched)="onUserAToucheFormulaire($event.fieldName)"
                        >
                            <div class="tds-form">
                                <mat-form-field class="col-12" [coreModelField]="nameOfForm('email')" [matInput]="emailInput">
                                    <mat-label i18n="@@DemandeRappel.Email">E-mail</mat-label>
                                    <input  matInput #emailInput="matInput" type="email" name="email" class="email"/>
                                </mat-form-field>
                                <mat-form-field class="col-12" *ngIf="model.choixIndicatifs as choix"
                                    [indicatifCoreModelField]="nameOfForm('indicatifTelephonique')"
                                    [telephoneCoreModelField]="nameOfForm('numeroTelephone')"
                                    [saisieTelephone]="saisieTelephone"
                                >
                                    <mat-label i18n="@@DemandeRappel.Telephone">Téléphone</mat-label>
                                    <core-saisie-telephone class="saisieTelephone" #saisieTelephone [choixIndicatifs]="choix"></core-saisie-telephone>
                                </mat-form-field>
                                <mat-form-field class="col-6" [coreModelField]="nameOfForm('nom')" [matInput]="nomInput">
                                    <mat-label i18n="@@DemandeRappel.Nom">Nom</mat-label>
                                    <input matInput #nomInput="matInput" type="text" name="nom" />
                                </mat-form-field>
                                <mat-form-field class="col-6" [coreModelField]="nameOfForm('prenom')" [matInput]="prenomInput">
                                    <mat-label i18n="@@DemandeRappel.Prenom">Prénom</mat-label>
                                    <input matInput #prenomInput="matInput" type="text" name="prenom" />
                                </mat-form-field>
                                <mat-form-field class="col-12" [coreModelField]="nameOfForm('commentaire')" [matInput]="commentaireInput">
                                    <mat-label i18n="@@DemandeRappel.Commentaire">Commentaire</mat-label>
                                    <textarea matInput #commentaireInput="matInput" type="text" name="commentaire" ></textarea>
                                </mat-form-field>
                                <core-captcha class="col-12"  [coreModelField]="nameOfForm('captchaToken')"></core-captcha>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="tds-card-footer">
            <button class="mybtn" fragment="motif-sejour" i18n="@@Devis.DemandeRappel.Valider" name="boutonValider" mat-flat-button [disabled]="!model.isEtapeValide"
                (click)="onUtilisateurACliqueSurBoutonValider()">
                Valider
            </button>
        </div>
    </div>
</ng-container>
